import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TreatmentSelection from './TreatmentSelection';
import StandardOfCares from './StandardOfCares';
import CircularProgress from '@mui/joy/CircularProgress';
import StandardOfCareFilter from './StandardOfCareFilter';
import Header from '../layouts/Header';
import { UserContext } from '../../App';
import SignInHandler from '../signin/SignInHandler';
import { getEndpoint } from '../utils/BackendAppConfig';
import { Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import { lime } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TREATMENT_TYPE_MAP } from '../trials/TrialsConstants';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import { parseSelectedCondition } from '../utils/Util';
import Cookies from 'js-cookie';

export default function StandardOfCaresMain({handleAuthSuccess, handleSignOut}) {
      const history = useHistory();
      const conditionName = parseSelectedCondition(history);
      const conditionNameQueryValue = conditionName.replace(/\s+/g, '_')

      const initialFilterValues = {
        matched: true,
        notsuitable: false,
        lackofinfo: true,
        strong: true,
        moderate: true,
        weak: true
    }

    Object.keys(TREATMENT_TYPE_MAP).forEach( treatmentShortName => initialFilterValues[treatmentShortName] = true );

    const userContext = React.useContext(UserContext);
  
    const SOCBox = styled(Box)(({ theme }) => ({
      [theme.breakpoints.down('lg')]: {
        marginLeft: '10px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '20px',
    },
      flexGrow: 1, 
      bgcolor: 'background.default', 
      p: 3,
    }));

    React.useEffect(() => {
      const fetchSOCs = (requestOptions, uriPrefix, retryCount) => {
        fetch(
          `${uriPrefix}`,
          requestOptions,
        )
        .then(response => response.json())
        .then(data => {
            if (!data.standardOfCareList && retryCount < 3) {
                console.log("Encounters timeout. Fetching standard of care again. ");
                setTimeout(() => { fetchSOCs(requestOptions, uriPrefix,retryCount+1); }, 1000);          
            }
          
            parseAndSetTreatmentNames(data.standardOfCareList);
            setStandardOfCares(data.standardOfCareList);
            setFilteredStandardOfCares(data.standardOfCareList);
        })
        .catch(error => {
          if (retryCount < 3) {
            console.log("Encounters errors. Fetching standard of care again. " + error);
            setTimeout(() => { fetchSOCs(requestOptions, uriPrefix, retryCount+1); }, 1000);
          }      
          console.error(error);
        })
  
      }

      if (userContext) {
        console.log("fetching with user id");
        const username = userContext.userProfile.userIdentification.userProfileId;
        const requestOptions = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userContext.jwtToken}`
          },
        };
        fetchSOCs(
          requestOptions, 
          `${getEndpoint("queryStandardOfCares")}?userProfileId=${username}&condition=${conditionNameQueryValue}&`,
          0,
        );

      } else {

        const userContextReady = Cookies.get('userContextReady');
        if (!userContextReady) {
          console.log("fetching without user id");
          const requestOptions = {
            method: 'GET',
          };
          fetchSOCs(
            requestOptions,
            `${getEndpoint("listStandardOfCares")}?condition=${conditionNameQueryValue}&`,
            0,
          );
        } else {
          console.log('user signed in');
        }
      }

    }, [userContext, conditionNameQueryValue]);

    const [treatmentName, setTreatmentName] = React.useState(null);
    const [standardOfCares, setStandardOfCares] = React.useState(null);
    const [treatmentNames, setTreatmentNames] = React.useState([]);
    const [filters, setFilters] = React.useState(initialFilterValues);
    const [filteredStandardOfCares, setFilteredStandardOfCares] = React.useState(null);

    const parseAndSetTreatmentNames = (socs) => {
      const result = [];
      socs.forEach(soc => {
        soc.treatmentDetails.forEach(item => {
          item.typeahead.forEach(t => result.push(t));
        });
      });

      setTreatmentNames([...new Set(result)].sort());

    }

    const setFilteredStandardOfCaresCallBack = (socs) => {
        if (socs && filteredStandardOfCares && socs.length !== filteredStandardOfCares.length) {
            setFilteredStandardOfCares(socs)
        }
    }

    const handleTreatmentChange = (name) => {
      setTreatmentName(name);
    }

    const handleFiltersUpdate = (updatedFilters) => setFilters(updatedFilters);

    const onClickInterestedIn = () => {
      history.push({
        pathname: 'trials',
        search: '?condition=' + conditionName
      })
    }

    return (
      <>
      
      <Header handleSignOut={handleSignOut} />
      <SignInHandler handleAuthSuccess={handleAuthSuccess} />
      <Grid container spacing={0}>
          <Grid item lg={1} xs={0} />
          <Grid item lg={10} xs={12}>
            <Box sx={{ display: 'flex'}}>
              <CssBaseline />
              <Hidden smDown>
             
                  <StandardOfCareFilter filters={filters} handleFiltersUpdate={handleFiltersUpdate} />
                
              </Hidden>

        
      
            <SOCBox
              component="main"
            >
              <Hidden smUp>
              <Box sx={{backgroundColor: lime[500], margin: 1, maxWidth: '90vw', padding: 1}}>
              This page is not optimized for mobile devices. For the best experience and access to advanced filtering options, please use a tablet or laptop.
              </Box>
              </Hidden>
            { (treatmentNames.length === 0 || standardOfCares == null) && <>
              <CircularProgress variant="solid" color="neutral" thickness={5} sx={{ '--CircularProgress-size': '50px', marginLeft: '50px' }}/>
              <p/>Loading standard of care options ...
              </>}
              {treatmentNames.length > 0 && filteredStandardOfCares && <>
              <Box sx={{margin: 1, width: {
                xs: '90vw', 
                sm: '90vw',
                md: 400, 
                lg: 800, 
                xl: 800, 
              }}}>
                <Typography variant='body1' sx={{marginTop: "0.1"}}>
                  Found <b>{filteredStandardOfCares.length}</b> standard of care options for <b>{conditionName}</b>.&nbsp;&nbsp;
                  <Button variant="text" 
                            onClick={onClickInterestedIn} sx={{textTransform: 'none', padding: 0, marginTop: -0.3, fontSize: '1rem'}}>Interested in clinical trial options?</Button>
                </Typography>
              </Box>
              <TreatmentSelection treatmentName={treatmentName} handleTreatmentChange={handleTreatmentChange} treatmentNames={treatmentNames}/>
              <p/>
              <StandardOfCares allStandardOfCares={standardOfCares} treatmentName={treatmentName} filters={filters} setFilteredStandardOfCaresCallBack={setFilteredStandardOfCaresCallBack}/>
              </>}

            </SOCBox>
          </Box>
      </Grid>
      <Grid item lg={1} xs={0}/>
    </Grid>
      
  </>
    );
  }
  