import React from 'react';
import { Route, Switch } from 'react-router-dom';


// Views 
import Home from './views/home/Home';
import APICallExample from './APICallExample';
import ProfileMain from './views/profile/ProfileMain';
import TrialsMain from './views/trials/TrialsMain';
import StandardOfCaresMain from './views/soc/StandardOfCaresMain';
import SignInWizard from './views/signin/SignInWizard';
import Cookies from 'js-cookie';
import TemplateInfoPage from './views/layouts/TemplateInfoPage';

export const UserContext = React.createContext(null);

// https://main.d33jbtqu47ut5c.amplifyapp.com/trials
export default function App() {

  const [userContext, setUserContext] = React.useState(null);

  const handleSignOut = () => setUserContext(null);
  const handleAuthSuccess = (userData) => {
    // Updating react context will force page refresh. 
    // On homepage, there is a hidden sign in handler that set user context
    // Thus we can only update user context when absolutely needed
    if (!userContext) {
      console.log("setting user context");
      setUserContext(userData);
      Cookies.set('userContextReady', true, { 
        expires: 1,
        path: '/',
        sameSite: 'strict'
      });
    } else {
      console.log("updating user context");
      const updatedUserContext = Object.assign(userContext, userData);
      setUserContext(updatedUserContext);
    }
    
  }
  
  // debug env vars
  console.log("environment stage: " + process.env.REACT_APP_ENV_STAGE)

  return (
    <UserContext.Provider value={userContext}>
      <Switch>
        <Route exact path="/" 
        render={(props) => <Home handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/trials" 
          render={(props) => <TrialsMain handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/standardOfCare" 
          render={(props) => <StandardOfCaresMain handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/api" component={APICallExample}/>
        <Route exact path="/profile" 
          render={(props) => <ProfileMain handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/signin" 
          render={(props) => <SignInWizard {...props} /> } />
        <Route exact path="/researchers" 
          render={(props) => <TemplateInfoPage handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/clinics" 
          render={(props) => <TemplateInfoPage handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/aboutus" 
          render={(props) => <TemplateInfoPage handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/breastcancer" 
          render={(props) => <TemplateInfoPage handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/lungcancer" 
          render={(props) => <TemplateInfoPage handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/learnmore" 
          render={(props) => <TemplateInfoPage handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/termofservice" 
          render={(props) => <TemplateInfoPage handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/privacypolicy" 
          render={(props) => <TemplateInfoPage handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
        <Route exact path="/faqs" 
          render={(props) => <TemplateInfoPage handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} {...props} /> } />
      </Switch>
    </UserContext.Provider>
  );
}
